import React, {useContext, useEffect} from "react"
import {serverSideTranslations} from "next-i18next/serverSideTranslations"
import NavBar from "../components/navigation/NavBar"
import Footer from "../components/footer/Footer"
import nextI18NextConfig from "../next-i18next.config.js"
import {useTranslation} from "next-i18next"
import MbpHead from "../components/head/MbpHead"
import FirebaseContext from "../context/FirebaseContext"
import {useRouter} from "next/router"
import {Facebook, Google, Twitter} from "react-bootstrap-icons"
import Typography from "../components/reusable/Typography/Typography"
import {colors} from "../colors/Colors"
import {getPageData} from "../pageData"

const Login = () => {
	const {t} = useTranslation()
	const title = t("MyBikePlan | Login")
	const description = ""
	const {userLoading, user, login} = useContext(FirebaseContext)
	const router = useRouter()
	const {redirect} = router.query

	useEffect(() => {
		if (user && !user.isAnonymous && !redirect) {
			router.push("/account").then()
		} else if (redirect && !user?.isAnonymous) {
			router.push(redirect as string).then()
		}
	}, [user])

	return (
		<>
			<MbpHead>
				<title>
					{title}
				</title>
				<meta name="description"
					content={description}/>
				<meta property="og:title"
					content={title}/>
				<meta property="og:description"
					content={description}/>
			</MbpHead>
			<NavBar/>
			<main>
				<div className="container mt-5 mb-5">
					<div className={"row justify-content-center"}>
						{user?.isAnonymous && !userLoading ? <div className={"col-12 col-md-6 col-lg-4 text-center"}>
							<Typography variant={"heading1"}
								semanticTag={"h1"}
								className={"mb-4"}>
								{t("login-title", {ns: "auth"})}
							</Typography>
							<Typography variant={"bodyLg"}
								semanticTag={"p"}
								className={"mb-4"}>
								{t("login-subtitle", {ns: "auth"})}
							</Typography>
							<div className={"row justify-content-center"}>
								<div className={"col-auto"}>
									<button className={"btn rounded-circle shadow p-2 pointer"}
										onClick={() => login("google")}>
										<Google size={36}
											color={colors.primary}/>
									</button>
								</div>
								<div className={"col-auto"}>
									<button className={"btn rounded-circle shadow p-2 pointer"}
										onClick={() => login("facebook")}>
										<Facebook size={36}
											color={colors.primary}/>
									</button>
								</div>
								<div className={"col-auto"}>
									<button className={"btn rounded-circle shadow p-2 pointer"}
										onClick={() => login("twitter")}>
										<Twitter size={36}
											color={colors.primary}/>
									</button>
								</div>
							</div>
						</div> : <div className={"col-auto"}>
							<div className={"spinner-border text-primary spinner-border-sm"}
								role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>}
					</div>
				</div>
			</main>
			<Footer/>
		</>
	)
}

export async function getStaticProps({locale}) {
	return {
		props: {
			...(await getPageData(locale, [])),
			...(await serverSideTranslations(locale, ["common", "URL", "search", "auth", "filters"], nextI18NextConfig))
		}
	}
}

export default Login
